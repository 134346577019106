import { useEffect, useState } from "react";
import classnames from "classnames";
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import StudentFields from "./innerComponents/StudentFields";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ApplicationState } from "store";
import { propertyListGetRequest } from "store/properties/action";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";

const ProfileCompleteness = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const propertiesList = useSelector((state: ApplicationState) => state.properties.list);
    const [lightNavTab, setlightNavTab] = useState<any>("1");
    const [parsedProperties, setParsedProperties] = useState([]);
    const [model, setModel] = useState("students")
    const lightNavToggle = (tab: any) => {
        if (lightNavTab !== tab) {
            setlightNavTab(tab);
        }
    };

    useEffect(() => {
        if (model) dispatch(propertyListGetRequest(model, { pageSize: "300" }));
    }, [dispatch, model])

    useEffect(() => {
        const parsedData = propertiesList.map((item: { valuesJson: string; id: any; }) => {
            if (item.valuesJson) {
                try {
                    const values = JSON.parse(item.valuesJson);
                    // Extracting id, key, and label
                    return {
                        key: values.key,         // Assuming values.key exists
                        label: values.label,     // Assuming values.label exists
                        id: item.id,             // Extracting the id from the top level
                        profileCompleteness: values.profileCompleteness
                    };
                } catch (error) {
                    console.error("Error parsing valuesJson", error);
                    return { error: "Invalid JSON" };
                }
            }
            return { error: "No valuesJson" };
        }).filter((item: { key: any; label: any; }) => item.key && item.label); // Filter out any invalid entries

        setParsedProperties(parsedData);
    }, [propertiesList]);

    return (
        <div>
            <SimpleBar>
                <div className="scrollable-container">
                    <Col xl={12} lg={12} xxl={12}>
                    <h4>{props.t("userpreferences.profile_completeness")}</h4>
                        <Card className="shadow-lg">
                            <CardBody>
                            <Nav pills className="nav-customs nav-danger mb-3">
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: lightNavTab === "1", })} onClick={() => {
                                            setModel("students")
                                            lightNavToggle("1");
                                        }} >
                                            {props.t("userpreferences.student_profile")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }} className={classnames({ active: lightNavTab === "2", })} onClick={() => {
                                            setModel("applications")
                                            lightNavToggle("2");
                                        }} >
                                           {props.t("userpreferences.application_profile")}
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={lightNavTab} className="text-muted">
                                    <TabPane tabId="1" id="nav-light-home">
                                        <StudentFields props={props} parsedProperties={parsedProperties} />
                                    </TabPane>

                                    <TabPane tabId="2" id="nav-light-profile">
                                        <StudentFields props={props} parsedProperties={parsedProperties} />
                                    </TabPane>

                                    <TabPane tabId="3" id="nav-colored-messages" >
                                        <div className="d-flex mb-1">
                                            <div className="flex-grow-1 me-3">
                                            </div>
                                            <div className="flex-shrink-0">
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
            </SimpleBar>
        </div>
    )
}
export default withTranslation()(ProfileCompleteness);