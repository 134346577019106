import React, { useState, useEffect } from 'react';
import { ListGroupItem } from 'reactstrap';
import { MenuItem } from './PermissionsManagement'; // Adjust the import as needed

export interface SideMenuItemProps {
  item: MenuItem;
  level?: number;
  onSelect: (item: MenuItem) => void;
  selectedItemId: string | number | null;
  shouldExpand?: string[];
}

const SideMenuItem: React.FC<SideMenuItemProps> = ({
  item,
  level = 0,
  onSelect,
  selectedItemId,
  shouldExpand = [],
}) => {
  // Determine if the item is expandable.
  const isExpandable = item.children && item.children.length > 0;
  
  // Initialize expanded state based on shouldExpand prop.
  const [expanded, setExpanded] = useState(isExpandable && shouldExpand.includes(item.id.toString()));

  // When shouldExpand changes, update the expanded state for expandable nodes.
  useEffect(() => {
    if (isExpandable) {
      setExpanded(shouldExpand.includes(item.id.toString()));
    }
  }, [shouldExpand, item.id, isExpandable]);

  // Use 'div' for expandable items and 'button' for leaf items.
  const Tag = isExpandable ? 'div' : 'button';

  return (
    <>
      <ListGroupItem
        tag={Tag}
        action={!isExpandable} // Only leaf items are clickable.
        onClick={() => {
          if (isExpandable) {
            setExpanded(!expanded);
          } else {
            onSelect(item);
          }
        }}
        active={!isExpandable && selectedItemId === item.id}
        style={{ paddingLeft: `${level * 20 + 10}px` }}
        className="border-0 d-flex align-items-center cursor-pointer"
      >
        {isExpandable && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!expanded);
            }}
            style={{ marginRight: '8px', cursor: 'pointer' }}
          >
            <i className={`mdi ${expanded ? 'mdi-chevron-down' : 'mdi-chevron-right'}`} />
          </span>
        )}
        <div>{item.name}</div>
      </ListGroupItem>
      {isExpandable && expanded && item.children.map(child => (
        <SideMenuItem
          key={child.id}
          item={child}
          level={level + 1}
          onSelect={onSelect}
          selectedItemId={selectedItemId}
          shouldExpand={shouldExpand}
        />
      ))}
    </>
  );
};

export default SideMenuItem;
