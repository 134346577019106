import React, { useState, useEffect } from 'react';
import {
	Collapse,
	ButtonGroup,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Input,
} from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Permission {
	id: string;
	name: string;
	type: string; // 'boolean' or 'dropdown'
	description?: string;
	options?: string | { label: string; value: string }[];
	value: any;
	permissions?: Permission[]; // Nested permissions
}

export interface PermissionDetailsProps extends WithTranslation {
	item: any; // The selected menu item with a "permissions" array.
	permissionValues: { [id: string]: any };
	onPermissionChange: (id: string, value: any) => void;
}

/**
 * getOptions
 * Parses the permission options into an array.
 */
const getOptions = (permission: Permission): { label: string; value: string }[] => {
	let options: { label: string; value: string }[] = [];
	if (typeof permission.options === 'string') {
		try {
			options = JSON.parse(permission.options);
		} catch (e) {
			options = [];
		}
	} else {
		options = permission.options || [];
	}
	return options;
};

/**
 * getOptionLabel
 * For dropdowns, returns the label corresponding to the current value.
 */
const getOptionLabel = (permission: Permission, currentValue: any): string => {
	if (currentValue === null || currentValue === undefined) {
		return '';
	}
	const options = getOptions(permission);
	const found = options.find(opt =>
		(opt.value ?? '').toString().toLowerCase() === currentValue.toString().toLowerCase()
	);
	return found ? found.label : currentValue;
};

/**
 * ExpandablePermissionItem Component
 * Renders a single permission control along with its name/description.
 * If nested permissions exist, an inline expand/collapse toggle is displayed.
 */
const ExpandablePermissionItem: React.FC<{
	permission: Permission;
	level?: number;
	permissionValues: { [id: string]: any };
	onPermissionChange: (id: string, value: any) => void;
	t: (key: string) => string;
}> = ({ permission, level = 0, permissionValues, onPermissionChange, t }) => {
	// Helper to determine if the permission qualifies as "truthy" for expansion.
	const canExpand = (): boolean => {
		if (permission.type === 'boolean') {
			const val = permissionValues[permission.id];
			return val === true || val === "true";
		} else if (permission.type === 'dropdown') {
			const val = permissionValues[permission.id];
			return Boolean(val) && val !== "false" && val !== "";
		}
		return false;
	};

	// Set initial expansion state based on current value.
	const computeInitialExpand = (): boolean => {
		return canExpand();
	};

	const [isExpanded, setIsExpanded] = useState<boolean>(computeInitialExpand());

	useEffect(() => {
		setIsExpanded(computeInitialExpand());
	}, [permissionValues, permission]);

	const hasChildren = permission.permissions && permission.permissions.length > 0;

	// Render the appropriate control element based on permission type.
	let control = null;
	if (permission.type === 'boolean') {
		control = (
			<div className="form-check form-switch">
				<Input
					className="form-check-input"
					type="checkbox"
					role="switch"
					id={`switch-${permission.id}`}
					checked={!!permissionValues[permission.id]}
					onChange={(e) =>
						onPermissionChange(permission.id, e.target.checked)
					}
				/>
			</div>
		);
	} else if (permission.type === 'dropdown') {
		control = (
			<ButtonGroup className="material-shadow">
				<UncontrolledDropdown>
					<DropdownToggle tag="button" className="btn btn-primary material-shadow-none">
						{permissionValues[permission.id]
							? getOptionLabel(permission, permissionValues[permission.id])
							: t('roles.select_permission')}{' '}
						<i className="mdi mdi-chevron-down"></i>
					</DropdownToggle>
					<DropdownMenu>
						{getOptions(permission).map(opt => (
							<DropdownItem
								key={opt.value}
								onClick={() =>
									onPermissionChange(permission.id, opt.value)
								}
							>
								{opt.label}
							</DropdownItem>
						))}
					</DropdownMenu>
				</UncontrolledDropdown>
			</ButtonGroup>
		);
	}

	return (
		<div style={{ marginLeft: level * 20 }} className="mb-3">
			<div className="card shadow-sm border-0">
				<div className="card-body d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						{hasChildren && (
							<span
								onClick={() => {
									if (canExpand()) {
										setIsExpanded(!isExpanded);
									}
								}}
								style={{
									cursor: canExpand() ? 'pointer' : 'not-allowed',
									marginRight: '10px',
								}}
							>
								<i className={`mdi ${isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-right'} fs-5`} />
							</span>
						)}
						<div>
							<div className="fw-bold">{permission.name}</div>
							{permission.description && (
								<div className="text-muted small">{permission.description}</div>
							)}
						</div>
					</div>
					<div>{control}</div>
				</div>
			</div>
			{hasChildren && (
				<Collapse isOpen={isExpanded}>
					{permission.permissions!.map(child => (
						<ExpandablePermissionItem
							key={child.id}
							permission={child}
							level={level + 1}
							permissionValues={permissionValues}
							onPermissionChange={onPermissionChange}
							t={t}
						/>
					))}
				</Collapse>
			)}
		</div>
	);
};

/**
 * PermissionDetails Component
 * Renders all permissions (including nested ones) for the selected menu item.
 */
const PermissionDetails: React.FC<PermissionDetailsProps> = ({
	item,
	permissionValues,
	onPermissionChange,
	t,
}) => {
	const permissions: Permission[] = item.permissions || [];
	return (
		<div>
			<h5 className="mb-3">
				{t('roles.permissions_for')}{' '}
				<span className="text-primary">{item.name}</span>
			</h5>
			{item.description && (
				<div className="text-muted mb-3">
					{item.description}
				</div>
			)}
			{permissions.map(permission => (
				<ExpandablePermissionItem
					key={permission.id}
					permission={permission}
					permissionValues={permissionValues}
					onPermissionChange={onPermissionChange}
					t={t}
				/>
			))}
		</div>
	);
};

export default withTranslation()(PermissionDetails);
