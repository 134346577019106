import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Button,
    Label,
    Input,
} from "reactstrap";
import Select from "react-select";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { teamCreateRequest, teamsListGetRequest } from "store/teams/action";
import { ApplicationState } from "store";
import { useEffect, useState } from "react";

const AddEntry = ({ t, show, onClose, parent }: any) => {
	const teamList = useSelector((state: ApplicationState) => state.teams.teams);
	const loading = useSelector((state: ApplicationState) => state.teams.loading);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [teams, setTeams] = useState<any>([]);
    const [team, setTeam] = useState<any>(null);
    const [name, setName] = useState<any>("");
    
    useEffect(() => {
        if(parent?.id) {
            setTeam({label: parent?.name, value: parent?.id})
        }
    }, [parent])

    useEffect(() => {
        const teams = teamList?.map(team => {
            return {
                label: team?.name,
                value: team?.id
            }
        })
        setTeams(teams);

    }, [teamList])
    const OnAddEntry = () => {
        let data = {
            name,
            parentTeamId: team?.value
        }
        const handleSuccess = (body: any) => {
            dispatch(teamsListGetRequest())
            setTeam(null);
            setName(null);
            onClose()
        }
        const handleFailure = (body: any) => {
        }
        dispatch(teamCreateRequest(data, handleSuccess, handleFailure))
    }

    const handleResetData = () => {
        setTeam(null);
        setName(null);
    }
    return (
        <Offcanvas
            direction="end"
            isOpen={show}
            id="offcanvasExample"
            toggle={() => {onClose(); setTeam(null)}}
            backdrop="static"
        >
            <OffcanvasHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={() => { onClose();  handleResetData()}}>
                <span className="m-0 me-2 text-white">
                {t("team.add.add_team")}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody>
                <Card className="border-0" style={{ boxShadow: 'none' }}>
                    <CardBody className="border-0 d-flex flex-column gap-4">
                        <div className="d-flex flex-column gap-1">
                            <Label htmlFor="exampleInputrounded" className="form-label">
                                {t("team.add.parent_team")}
                            </Label>
                            <div className="w-100 select-element">
                                <Select
                                    placeholder={t("team.add.select_team")}
                                    value={team}
                                    // onInputChange={handleInputChange}
                                    onChange={setTeam}
                                    options={teams}
                                    classNamePrefix="js-example-data-array"
                                    isLoading={false}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-1">
                            <Label
                                htmlFor="id-field"
                                className="form-label"
                            >
                                {t("team.add.name")}<span className="text-danger"> *</span>
                            </Label>
                            <Input
                                name="name"
                                id="id-field"
                                className="form-control"
                                placeholder={t("team.add.enter_team_name")}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                    </CardBody>
                </Card>
            </OffcanvasBody>
            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                <Button
                    color="primary"
                    className="btn w-100"
                    onClick={() => OnAddEntry()}
                    disabled={loading || !name}
                >
                    {loading ? t("team.add.btn_add_team_loading") : t("team.add.btn_add_team")}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(AddEntry);

