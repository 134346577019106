import React, { useState, useEffect } from 'react';
import { Row, Col, ListGroup } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import SideMenuItem from './SideMenuItem';
import PermissionDetails from './PermissionDetails';

export interface MenuItem {
  id: string | number;
  name: string;
  description?: string;
  children: MenuItem[]; // refine type as needed
  permissions: any[];
}

interface PermissionsManagementProps extends WithTranslation {
  accessData: MenuItem[]; // The full permissions tree.
  setAccessPermissions: (data: any) => void; // Update parent's permission lookup.
  setAccessData: (data: any) => void; // Update parent's full tree.
  accessPermissions: any; // Current permission values.
  preAccessPermissions?: any;
}

// (updateTree remains unchanged)
const updateTree = (items: any[], id: string, newValue: any): any[] => {
  return items.map(item => {
    if (item.id.toString() === id) {
      return { ...item, value: newValue };
    }
    let updatedPermissions = item.permissions;
    if (Array.isArray(item.permissions)) {
      updatedPermissions = updateTree(item.permissions, id, newValue);
    }
    let updatedChildren = item.children;
    if (Array.isArray(item.children)) {
      updatedChildren = updateTree(item.children, id, newValue);
    }
    return { ...item, permissions: updatedPermissions, children: updatedChildren };
  });
};

const PermissionsManagement: React.FC<PermissionsManagementProps> = ({
  accessData,
  setAccessPermissions,
  setAccessData,
  preAccessPermissions,
  t,
}) => {
  // Ensure accessData is an array.
  const menuItems: MenuItem[] = Array.isArray(accessData) ? accessData : [];
  // State for the currently selected leaf.
  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);
  // State for the auto–expand path (array of string IDs along the path to the first leaf).
  const [autoExpandPath, setAutoExpandPath] = useState<string[]>([]);

  // Recursive function: find the path (array of IDs as strings) to the first leaf node.
  const findPathToFirstLeaf = (items: MenuItem[]): string[] => {
    for (const item of items) {
      if (item.children && item.children.length > 0) {
        const subPath = findPathToFirstLeaf(item.children);
        if (subPath.length > 0) {
          return [item.id.toString(), ...subPath];
        }
      } else {
        return [item.id.toString()];
      }
    }
    return [];
  };

  // Helper: find a node by its ID.
  const findNodeById = (items: MenuItem[], id: string): MenuItem | null => {
    for (const item of items) {
      if (item.id.toString() === id) return item;
      if (item.children && item.children.length > 0) {
        const found = findNodeById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  // On initial load, if nothing is selected, compute the auto–expand path and auto–select the first leaf.
  useEffect(() => {
    if (menuItems.length > 0 && !selectedItem) {
      const path = findPathToFirstLeaf(menuItems);
      console.log("Auto expand path:", path);
      if (path.length > 0) {
        setAutoExpandPath(path);
        const leafId = path[path.length - 1];
        const leafNode = findNodeById(menuItems, leafId);
        console.log("Auto selecting leaf:", leafNode);
        if (leafNode) {
          setSelectedItem(leafNode);
        }
      }
    }
  }, [menuItems, selectedItem]);

  // Local state for permission lookup values.
  const [permissionValues, setPermissionValues] = useState<{ [id: string]: any }>(
    preAccessPermissions || {}
  );

  useEffect(() => {
    setPermissionValues(preAccessPermissions || {});
  }, [preAccessPermissions]);

  useEffect(() => {
    setAccessPermissions(permissionValues);
  }, [permissionValues, setAccessPermissions]);

  // Update permission tree on change.
  const handlePermissionChange = (id: string, value: any) => {
    setPermissionValues(prev => ({ ...prev, [id]: value }));
    setAccessData((prevData: any[]) => updateTree(prevData, id, value));
  };

  const handleMenuSelect = (item: MenuItem) => {
    setSelectedItem(item);
  };

  return (
    <Row>
      {/* Left: Side Menu */}
      <Col md={3} className="border-end" style={{ minHeight: '70vh' }}>
        <div className="p-3">
          <h5 className="mb-3 text-primary">{t('roles.menu')}</h5>
          {menuItems && menuItems.length ? (
            <ListGroup>
              {menuItems.map(item => (
                <SideMenuItem
                  key={item.id}
                  item={item}
                  level={0}
                  onSelect={handleMenuSelect}
                  selectedItemId={selectedItem ? selectedItem.id : null}
                  shouldExpand={autoExpandPath}
                />
              ))}
            </ListGroup>
          ) : (
            <div>{t('roles.no_menu_items_available')}</div>
          )}
        </div>
      </Col>
      {/* Right: Permission Details */}
      <Col md={9}>
        <div className="p-3">
          {selectedItem ? (
            <PermissionDetails
              item={selectedItem}
              permissionValues={permissionValues}
              onPermissionChange={handlePermissionChange}
            />
          ) : (
            <div className="text-center text-muted">
              {t('roles.please_select_a_menu_item_to_view_its_permissions')}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(PermissionsManagement);
