import { Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import BreadCrumb from "Components/Common/BreadCrumb";
import UserProfile from "./UserProfile";
import { ToastContainer } from "react-toastify";

const index = ({ t }: any) => {
  document.title = "DTS | Zilter";

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("common.profile_and_preferences")} pageTitle={t("common.profile_and_preferences")} />
          <Row>
            <ToastContainer />
            <UserProfile />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withTranslation()(index);
